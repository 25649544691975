import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItem";

const PAGE_SIZE = 6;

const CarListing = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state

  const fetchDataFromBackend = async () => {
    setLoading(true); // Set loading state when starting fetch

    try {
      const response = await fetch('https://automatrulatebackend-246f1b5aea0c.herokuapp.com/cars'); // Fetch all cars

      if (!response.ok) {
        throw new Error('Failed to fetch car data');
      }

      const cars = await response.json();
      const temporaryArr = [];

      // Process the data (assuming 'images' field contains URLs)
      for (const carData of cars) {
        const images = carData.image_paths || [];

        temporaryArr.push({ id: carData.id, ...carData, images });
      }

      setCars(temporaryArr); // Set all cars in state
    } catch (err) {
      console.error('Error fetching car data:', err);
    }

    setLoading(false); // Reset loading state after fetching
  };

  useEffect(() => {
    fetchDataFromBackend(); // Fetch all cars when component mounts
  }, []);
  if (!cars) return <div>Se incarca...</div>;

  return (
    <Helmet title="Masini">
      <CommonSection title="Masini disponibile" />

      <section>
        <Container>
          <Row>
            {cars.map((item) => (
              <CarItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarListing;
