import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import "../../styles/car-item.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faCogs, faGasPump, faClock, faSlidersH } from '@fortawesome/free-solid-svg-icons';

const CarItem = (car) => {
  return (
    <Col lg="4" md="4" sm="6" className="mb-5">
      <div className="car__item fade-effect">
        <Carousel showArrows autoPlay infiniteLoop useKeyboardArrows>
          {car.item.images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Car ${car.item.model}`} className="w-100" />
            </div>
          ))}
        </Carousel>

        <div className="car__item-content mt-2">
          <h4 className="section__title text-center">{car.model}</h4>
          <h6 className="rent__price text-center mt-2">
            Pret - <span> €{car.item.price}</span>
          </h6>

          <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
            <span className=" d-flex align-items-center gap-1">
            <FontAwesomeIcon icon={faCar} style={{ color: "#f9a826" }} /> {car.item.make} {car.item.model}
            </span>
            <span className=" d-flex align-items-center gap-1">
              <FontAwesomeIcon icon={faCogs} style={{ color: "#f9a826" }} /> {car.item.engine}
            </span>
            <span className=" d-flex align-items-center gap-1">
            <FontAwesomeIcon icon={faGasPump} style={{ color: "#f9a826" }} /> {car.item.fuel}
            </span>
          </div>

          <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
            <span className=" d-flex align-items-center gap-1">
              <FontAwesomeIcon icon={faCar} style={{ color: "#f9a826" }} /> {car.item.body}
            </span>
            <span className=" d-flex align-items-center gap-1">
            <FontAwesomeIcon icon={faSlidersH} style={{ color: "#f9a826" }} /> {car.item.transmission}
            </span>
            <span className=" d-flex align-items-center gap-1">
            <FontAwesomeIcon icon={faClock} style={{ color: "#f9a826" }} /> {car.item.year}
            </span>
          </div>

          <div className="d-flex">
          <button className="w-50 car__item-btn car__btn-rent">
            <Link to={`/cars/${car.item.id}`}>Detalii</Link>
          </button>
          <button className="w-50 car__item-btn car__btn-rent">
          <a href="tel:+0741383836">
            Suna
          </a > 
          </button>
        </div>

        </div>
      </div>
    </Col>
  );
};

export default CarItem;
